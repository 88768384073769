import React, {useEffect, useState, useLayoutEffect, Fragment} from 'react'
import ReactDropzone from "react-dropzone";
import {useSelector} from "react-redux";

import {Card, Spinner} from 'react-bootstrap'
import swal from 'sweetalert'
import Axios from 'axios'
import $, {trim} from 'jquery'

import Pagination from '../Pagination'

const URLBack = process.env.REACT_APP_BACKEND_URL;

    
function validate(event){
    if(event.target.validity.valueMissing){
        event.target.setCustomValidity("Veuillez remplir ce champ");
    }else{
        event.target.setCustomValidity("");
    }
    return true
}

export default function CategorieEdit(props){

    const user = useSelector(state => state.logger); 
    const [allCategorie, setAllCategorie] = useState([]);
    const [loading, setLoading] = useState(true)
    const [name, setName] = useState("")
    const [Photos, setPhotos] = useState([])
    const [test, setTest] = useState(false)
    const [refresh, setRefresh] = useState()


    // const currentPosts = allCategorie.slice(indexOfFirstPost, indexOfLastPost)

    const onDropRejectedFile = (arg) => {
        let errors = arg[0].errors;
        errors.forEach(error => {
            if (error.code === "file-invalid-type") {
                swal({
                    icon: "error",
                    text: "Le fichier doit être une image."
                })
            } else if (error.code === "too-many-files") {
                swal({
                    icon: "error",
                    text: "Un fichier à la setAllCategorieois"
                })
            } else {
                swal({
                    icon: "error",
                    text: error.message
                })
            }
        });
        setTest(false)
    }

    const onDropFile = (files, key, annonce) =>{
        var tempFiles = [...Photos];
        for(let i=0; i < files.length; i++){
            var photo = Object.assign(files[i],{
                id: annonce._id,
                index: key,
                preview: URL.createObjectURL(files[i])
            }
        );
            
            tempFiles.push(photo);
        }
        setPhotos(tempFiles)
        setTest(true)

    }

    const handlerSubmitUpdate = (event, annonce, index) =>{
        event.preventDefault();
        $(`input[id=btn-update][name=${index}]`).val("Chargement...")
        const formData = new FormData();
        (name==="") ? formData.append("designation", annonce.designation) : formData.append("designation", trim(name));
        Photos.reverse().forEach((image) => {
            if( index === image.index){
                formData.append("images", image)}
            }
        )
        Axios.patch(URLBack+ "/categorie/"+annonce._id, formData,{
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Barer " + user.current_user.token
            }
        }).then((response) => {
            swal({
                title: "Mise à jour réussie.",
                text: response.data.success,
                icon: "success",
                button: {
                    text: "OK",
                    value: true
                }
            })
            // $(`input[id=btn-update][name=${index}]`).val("Modifier");
            setRefresh({});
        })
    }

    const handlerSubmitDelete = (event, item, index) => {
        event.preventDefault();
        $(`input[id=btn-delete][name=${index}]`).val("Chargement...")
        swal({
            title: "Confirmation",
            text: "Voulez-vous vraiment supprimer cette catégorie ?",
            icon: "warning",
            buttons: {
                cancel: "Annuler",
                confirm: "Confirmer"
            },
            dangerMode: true,
        }).then((result) => {
            if(result){
                Axios.delete(URLBack + "/categorie/"+item._id, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Barer " + user.current_user.token
                    }
                }).then((response) => {
                    if(response.data.errorAnnonce){
                        swal({
                            title: "Cette catégorie possède des annonces",
                            icon: "error",
                            button: {
                                text: "OK",
                                value: true
                            }
                        })
                        setRefresh({});
                    }else if(response.data.error){
                        swal({
                            title: "Erreur!",
                            icon: "error",
                            button: {
                                text: "OK",
                                value: true
                            }
                        })
                        setRefresh({})

                    }else{
                        swal({
                            icon: "success",
                            title: "Suppression réussie !",
                            button: {
                                text: "OK",
                                value: true
                            }
                        })
                        setRefresh({})
                    }
                })
            }else   $(`input[id=btn-delete][name=${index}]`).val("Supprimer")
        })

    }
    
    useEffect(() => {
        let mounted = true
        async function fetchData(){
            const response = await Axios.get(props.urlCategorie);
            setAllCategorie(response.data);
            setLoading(false);
            let categories = {
                allCategorie: response.data
            }
            categories =JSON.stringify(categories);
            localStorage.setItem("categories", categories);
            return response
        }
        if(mounted){
            fetchData();
        }else
        return (() => mounted = false)
    },[ props, refresh])

    const [CurrentPage, setCurrentPage] = useState(1);
    const [PostsPerPage] = useState(5);
    const indexOfLastPost = CurrentPage * PostsPerPage;
    const indexOfFirstPost = indexOfLastPost - PostsPerPage;
    
    const AllCategorie = () =>{

    }


    return(
        <div className="container-edit-categorie">
            {(!loading) ? (
            <>
                {/* <div>
                    <Pagination dataLength={allCategorie.length} postPerPage={PostsPerPage} paginate={(number) => setCurrentPage(number)} />
                </div> */}
                {allCategorie.map((item, key) => {                 
                    return(
                        <Fragment key={key}>
                        
                            <Card className="div-card-categorie-edit">
                                <div className="div-image-categorie-edit">
                        
                                    <ReactDropzone
                                        multiple={false}
                                        onDropRejected={(arg) => onDropRejectedFile(arg, key)}
                                        onDrop={(files) => onDropFile(files, key, item)}
                                        accept="image/*">
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} style={{ height : '100%' }}>
                                                    
                                                <input {...getInputProps()}/>

                                                <div 
                                                    className="w3-display-container imageContent-categorie bord-dash bord-dash-gray"
                                                    style={{ height : '100%' }}>

                                                        {
                                                            (test)
                                                            ?
                                                            (Photos.map((photo, indexPhoto) => (
                                                                
                                                                    (photo.index === key)
                                                                    ?
                                                                    (
                                                                        <img
                                                                        style={{position: "absolute"}}
                                                                        key={indexPhoto}
                                                                        src={photo.preview}
                                                                        className="imgDrop" alt={"image_" + key}
                                                                    />
                                                                    )
                                                                    :
                                                                    
                                                                    (
                                                                        <img
                                                                            style={{position: "absolute"}}
                                                                            key={indexPhoto}
                                                                            src={URLBack + "/images/" + item.photo}
                                                                            className="imgDrop" alt={"image_" + key}
                                                                        />
                                                                    )
                                                                
                                                            )))
                                                            :
                                                            (<img
                                                            
                                                                src={URLBack + "/images/" + item.photo}
                                                                style={{ width: "100%", borderRadius: "" }}
                                                                className="imgDrop" alt={"image_" + key}
                                                                
                                                            />)
                                                        }
                                                        
                                                </div>  
                                            </div>
                                        )}
                                    </ReactDropzone>
                        
                                </div>
                                <form className="div-form-categorie-edit" onSubmit={(event) => handlerSubmitUpdate(event, item, key)}>
                                    <div className="div-input-categorie-edit">
                                        <input
                                            
                                            required
                                            onInvalid={validate}
                                            onInput={validate}
                                            type="text" 
                                            defaultValue={item.designation}
                                            onKeyUp={(event => setName(event.target.value))}
                                        />
                                    </div>
                                    <div className="div-btn-categorie-edit">
                                        <input type="submit" id="btn-update" name={key} value="Modifier"/>
                                        <input type="submit" id="btn-delete" name={key} onClick={(event) => handlerSubmitDelete(event, item, key)} value="Supprimer"/>
                                    </div>
                                </form>
                            </Card>            
                        </Fragment>)
                    }
                )}
            </>
            )
            :
            <center><Spinner className="align-self-center" animation="border" variant="warning" /></center>}
        </div>
    )
}