import React, {useState, useEffect, useLayoutEffect, Fragment} from 'react';
import {useSelector} from "react-redux";
import * as FaIcons from "react-icons/fa";
import { faTimes, faCamera } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert'
import Axios from 'axios'
import $, {trim} from 'jquery'
import { Spinner, Card } from "react-bootstrap";

const URLBack = process.env.REACT_APP_BACKEND_URL;


function validate(event){
    if(event.target.validity.valueMissing){
        event.target.setCustomValidity("Veuillez remplir ce champ");
    }else{
        event.target.setCustomValidity("");
    }
    return true
}


export default function SousCategorieListe(props){

    const user = useSelector(state => state.logger);
    const [loading, setLoading] = useState(false);
    const [allSousCategorie, setAllSousCategorie] = useState([]);
    const [allCategorie, setAllCategorie] = useState([]);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const requireAsterix = <span style={{ color: "rgb(248, 133, 1)", alignSelf:"flex-start" }}>*</span>;
    const [showListCategorie, setShowListcategorie] = useState(false);
    const [categorieSelected, setCategorieSelected] = useState("Choisissez une catégorie");
    const [categorieInfo, setCategorieInfo] = useState({
        image: "",
        designation: ""
    })
    const handlerSubmitDelete = (event, sousCatId) =>{
        event.preventDefault();
        $(`[id=btnDel][name=${sousCatId}]`).text("Chargement...")
        swal({
            title: "Voulez-vous vraiment supprimer?",
            icon: "warning",
            buttons: {
                confirm: "Confirmer",
                cancel: "Annuler"
            }
        }).then(res =>{
            if (res===true){
                return(
                    Axios.delete(URLBack+"/produit/"+sousCatId, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Barer " + user.current_user.token
                        }
                    }).then(result => {
                        if (result.data.success) {
                            swal({
                                title: "Sous-catégorie supprimée",
                                icon: "success",
                                button: {
                                    text:"OK",
                                    value:true
                                }
                            })
                            setDeleteSuccess(!deleteSuccess)
                        }else{
                            swal({
                                title: "Des annonces utilisent cette sous-catégorie!",
                                icon: "error",
                                button: {
                                    text:"OK",
                                    value:false
                                }
                            })
                            $(`[id=btnDel][name=${sousCatId}]`).text("Supprimer")
                        }
                    })
                )

            } else{
                $(`[id=btnDel][name=${sousCatId}]`).text("Supprimer")
                return null
            }
        })

    }
    const handleSubmitUpdate = (event, sousCat) => {
        event.preventDefault();
        $(`[id=btnUpdate][name=${sousCat.produit._id}]`).val("Chargement...")
        swal({
            title: "Voulez-vous vraiment modifier?",
            icon: "warning",
            buttons: {
                confirm: "Confirmer",
                cancel: "Annuler"
            }
        }).then(res=>{
            if (res) {
                Axios.patch(URLBack+"/produit/"+sousCat.produit._id, {designation: sousCat.produit.designation}, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Barer " + user.current_user.token
                    }
                }).then(result=>{
                    if (result.data.success) {
                        swal({
                            title: "Modifiée avec succès",
                            icon: "success",
                            button: {
                                text:"OK",
                                value:true
                            }
                        })
                        $(`[id=btnUpdate][name=${sousCat.produit._id}]`).val("Modifier")
                    }else{
                        swal({
                            title: "une erreur est survenue",
                            icon: "danger",
                            button: {
                                text:"OK",
                                value:false
                            }
                        })
                        $(`[id=btnUpdate][name=${sousCat.produit._id}]`).val("Modifier")
                    }
                })
            }else {
                $(`[id=btnUpdate][name=${sousCat.produit._id}]`).val("Modifier")
                return null
            }
        })

    }

    const setNewDesignation = (value, sousCat) => {
        const index = allSousCategorie.findIndex(ele=>ele==sousCat);
        const elem = allSousCategorie.find(ele=>ele==sousCat);
        allSousCategorie[index].produit.designation = value;
    }

    const setCategorie = (cat) => {
        setCategorieSelected(cat.designation);
        setLoading(false)
        setCategorieInfo({
            image: URLBack+"/images/"+cat.photo,
            designation: cat.designation
        })
        setTimeout(()=>{
            setLoading(true);
        }, 1500)

    }
    const getAll = async () => {
        const categories = JSON.parse(localStorage.getItem("categories")).allCategorie;
        setAllCategorie(categories);
        setAllSousCategorie([]);
        var tab = [];
        await Axios.get(props.urlToGet).then((allprod)=>{
            allprod.data.map((prod) => {
                const cate = categories.find(elem => elem._id == prod.categorie);
                if (cate.designation === categorieSelected){
                    const item = {
                        produit: prod,
                        categorie: cate
                    }
                    tab.push(item)
                }
            })
        })
        setAllSousCategorie(tab);
    }
    useLayoutEffect( () => {
        getAll();
    },[ props, deleteSuccess, categorieSelected])

    return(
        <div className="form-sousCategorieEdit">
            <div className="dropCategorieSCEdit" onClick={()=> showListCategorie?setShowListcategorie(false):setShowListcategorie(true)}>
                <div className="dropBtnCategorieSCEdit">
                    {categorieInfo.designation===""?(<div>Choisissez une categorie{requireAsterix}</div>):(<div className="catInfo"><img src={categorieInfo.image} alt={categorieInfo.image} width="70px" height="70px"/> <span>{categorieInfo.designation}</span></div>)}
                    <div className="btnToDrop"><FaIcons.FaChevronDown /></div>
                </div>
                { showListCategorie ? (
                    <div className="dropdownCategorieSCEdit-content">
                        {
                            allCategorie.map((cat, key) => (
                                <div key={key} className="oneCategorieSCEdit" onClick={() => {setCategorie(cat); setShowListcategorie(false)}}> <img src={URLBack+"/images/"+cat.photo} alt={cat.photo} width="70px" height="70px"/><span className="designation"> {cat.designation}</span></div>
                            ))
                        }
                    </div>) : (null) }
            </div>
            <div className="listSousCategorie">
                {categorieSelected === "Choisissez une catégorie"?(
                    <div style={{textAlign:"center", marginTop:"2em"}}>{categorieSelected}</div>
                ):(
                    (loading === false ? (
                            <div style={{textAlign:"center", marginTop:"2em"}}>
                                <Spinner animation="border" role="status" variant="warning" ></Spinner>
                            </div>)
                        :(
                            (allSousCategorie.length===0)
                                ?
                                <div className="d-flex justify-content-center align-items-center">
                                    <h4>AUCUN SOUS CATÉGORIES TROUVÉES</h4>
                                </div>
                                :
                                allSousCategorie.map((sousCat, key) =>
                                    <Fragment key={key}>
                                        <form className="formEditSC">
                                            <div className="txtSousCate">
                                                {key + 1}
                                            </div>

                                            <input style={{textAlign:"center"}}
                                                required
                                                onInvalid={validate}
                                                onInput={validate}
                                                type="text"
                                                defaultValue={sousCat.produit.designation}
                                                disabled
                                            />
                                            <div className="div-btn-sousCategorie-edit">
                                                <button className="btnSC btn-danger" id="btnDel" name={sousCat.produit._id} onClick={(event) => handlerSubmitDelete(event, sousCat.produit._id)}>Supprimer</button>
                                            </div>
                                        </form>
                                    </Fragment>)
                        ))
                )}
            </div>

        </div>
    )
}