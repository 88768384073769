import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import logo from "./Logo_InMyBush.png";
import '../css/header.css';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Whisper } from 'rsuite';
import Axios from "axios";
import { loggoutUser } from "../../my_redux/actions/logginUser";
import { Spinner } from "react-bootstrap";
import userPic from "./user-solid.svg";
import logoutPic from "./sign-out-alt-solid.svg"
import dashboardPic from "./columns-solid.svg";

const url = process.env.REACT_APP_BACKEND_URL;

const Speaker = ({ content, ...props }) => {
    const dispatch = useDispatch();
    return (
        <Popover  {...props}>
            <div className="adminName">{props.name}</div>
            <div className="adminUsername">@{props.username}</div>
            <hr style={{borderTopColor:"black"}}/>
            <div onClick={() => { window.location.assign("/mon_compte") }} className="itemProfilAdmin"><img src={userPic} alt="userPic" width="15px" height="20px"/>&nbsp; &nbsp; Mon compte</div>
            <div onClick={() => { window.location.assign("/admin/dashboard") }} className="itemProfilAdmin"><img src={dashboardPic} alt="dashboardPic" width="15px" height="20px"/>&nbsp;Dashboard</div>
            <div onClick={() => {
                setTimeout(() => {
                    dispatch(loggoutUser());
                    localStorage.removeItem("categories");
                    window.location.assign("/admin/login")
                }, 1000);
            }} className="itemProfilAdmin"><img src={logoutPic} alt="logoutPic" width="15px" height="20px"/>&nbsp;Déconnexion</div>
        </Popover>
    );
};


function Header() {

    const userLog = useSelector(state => state.logger);

     const [loginContainer, setLoginContainer] = useState(null);
    const [photoUser, setPhotoUser] = useState("");
    const [elementTop, setElementTop] = useState("");
    const [loading, setLoading] = useState(true);

    const checkPhotoUser = (photo) => {
        var link = url+"/images-get/"+photo;
        const default_pdp = "/image/avatar_default.png";
        var xhr = new XMLHttpRequest();
        xhr.open('GET', link);
        xhr.onreadystatechange = function() {           
            if (xhr.readyState === 4) {
                switch(xhr.status) {
                    case 200 :
                        setPhotoUser(link);
                    break;
                    case 404 :
                        setPhotoUser(default_pdp);
                    break;
                    case 402 :
                        setPhotoUser(link);
                    break;
                }
            }
        }; 
        xhr.send();
    }

    const getCurrentUser = (user) => {
        if (user) {
            Axios.get(url + '/user/' + user.current_user.id)
                .then(res => {
                    (res.data.photo)?(
                        checkPhotoUser(res.data.photo)
                    ):(setPhotoUser("/image/avatar_default.png"))
                    setLoading(false)
                    setLoginContainer(
                        <>
                            <Whisper
                                trigger="click"
                                enterable
                                placement={elementTop}
                                speaker={<Speaker name={res.data.nom ? res.data.nom : ""} username={res.data.username} content={"null"} />}
                            >
                                <div className="adminAvatar" style={{ marginTop: "20%" }}>
                                {(loading?(<div style={{width:"60px", height:"50px",paddingTop:"30%"}}>
                                    <Spinner animation="border" role="status" variant="warning" >
                                    </Spinner></div>):(<img src={photoUser} className="img-circle" style={{ width: 70, height: 70, objectFit: "cover", objectPosition: "center",
                  border: "1px solid grey", }} alt="avatar" />))}
                                </div>
                            </Whisper>
                        </>
                    );
                })
                .catch(error => {
                    alert(error.message);
                });
        }
    };

    useEffect(() => {
        
        $(document).ready(() => {
            placement();
            $(window).resize(() => {
                placement();
            });
            function placement() {
                var windowWidth = $(window).width();
                if (windowWidth > 1300) {
                    setElementTop("bottom");

                } else {
                    setElementTop("leftStart");
                }
            }
        });
        getCurrentUser(userLog);
    }, [photoUser, elementTop]);

    return (
        <>
            <div className="header-admin">
                <div className="container">
                    <div className="contentHeaderAdmin">
                        <div className="logoHeaderAdmin">
                            <img src={logo} style={{
                                width: '8em',
                                height: '6em',
                                cursor: "pointer"
                            }} onClick={()=>window.location.assign("/")} />
                        </div>
                        <div className="adminAvatar">
                            {loginContainer}
                        </div>
                    </div>             
                </div> 
            </div>
            
        </>
        
    )
}

export default Header
