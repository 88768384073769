import React, { useState} from 'react';
import { Nav,Accordion} from 'react-bootstrap';
import SousCategorie from './SousCategorie';

import Categorie from './Categorie';



import '../../css/produit.css';
import { useEffect } from 'react';

function Produit() {

    const [varTabContent, setTabContent] = useState(<Categorie/>);

    return (
        <div className="container-admin-produit">
            {/* <h3 align="center" className="sectionTitle">PRODUITS</h3> */}
            <Nav fill variant="tabs" defaultActiveKey="link-1">
                <Nav.Item>
                    <Nav.Link
                        eventKey="link-1"
                        className="div-admin-produit-link"
                        onClick={() => setTabContent(<Categorie/>)}
                    >CATEGORIE</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="link-2"
                        className="div-admin-produit-link"
                        onClick={() => setTabContent(<SousCategorie/>)}
                    >SOUS-CATEGORIE</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="container-tabs-produit">
                {varTabContent}
            </div>
        </div>
    )
}

export default Produit
