import React, { useRef, useState } from "react";
import {Link} from 'react-router-dom'
import {useSelector} from "react-redux";
import "./footer.css";
import logo from "./Logo_InMyBush.png";
import { useHistory } from "react-router-dom";
import ModalToSubscribe from "../../../components/modalToSubscribe/ModalToSubscribe";
import { FaFacebook, FaLinkedin, FaMapMarkerAlt, FaPhoneAlt, FaTwitter } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

function Footer () {
        const user = useSelector(state => state.logger);
        const currentYear = new Date().getFullYear();
        const [emailNewLetter, setEmailNewLetter] = useState('');
        const [show, setShow] = useState(false);
        const emailInputRef = useRef(null);
        const history = useHistory()

        const handleSubscribe = async (e) => {
            e.preventDefault();
            if (emailNewLetter.trim()==''){
                if (emailInputRef.current) {
                    emailInputRef.current.focus();
                  }
                  return;
            }
            setShow(true);
        };
        
        return (
            <div id="footerContainer">
               <ModalToSubscribe {...{ show, setShow, emailNewLetter, setEmailNewLetter }} />
                    <div className="container">
                    <div className="row" style={{marginBottom: 25, justifyContent: "space-between"}}>
                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                            <h3>INFORMATIONS GÉNÉRALES<div className="underline"></div></h3>
                            {user === null?(
                                <Link  className="link" to="/login"><div className="txt-login">Se connecter</div></Link> 
                            ):(
                                <Link  className="link" to="/mon_compte"><div className="txt-login">Mon compte</div></Link> 
                            )}
                            <Link className="link" to="/comment-ça-marche"><div className="txt-marche">Comment ça marche</div></Link> 
                            <Link className="link" to="/mentions_legales"><div className="txt-marche">Mentions Légales</div></Link> 
                            <a className="link" onClick={() => {
                                history.push('/contact')
                                window.scrollTo(0, 250)
                            }}>
                                <div className="txt-marche">Nous contacter</div>
                            </a>
                        </div>
                        <div className="col-sm-12 col-md-5 col-lg-5">
                            <h3>CATÉGORIES<div className="underline"></div></h3>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="link">Fruits</div>
                                    <div className="link">Légumes</div>
                                    <div className="link">Viandes</div>
                                    <div className="link">Plantes graines</div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="link">Condiments et épices</div>
                                    <div className="link">Poissons et fruit de mer</div>
                                    <div className="link">Oeufs</div>
                                    <div className="link">Bois</div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="link">Paniers</div>
                                    <div className="link">Produits Transformés</div>
                                    <div className="link">Animaux</div>
                                    <div className="link">Plantes médicinales</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <h3>NEWSLETTER<div className="underline"></div></h3>
                            <form onSubmit={handleSubscribe}>
                                <input 
                                   ref={emailInputRef}
                                    type="text" id="emailTextField" placeholder="Votre adresse mail" value={emailNewLetter} 
                                    onChange={(e) => setEmailNewLetter(e.target.value)} />
                                <input type="submit" style={{ minWidth: 200, marginBottom: 10 }} className="btn-newsletter my-btn c-green" value="Envoyer" />
                            </form>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: 25, justifyContent: "space-between"}}>
                        <div className="container-apropos col-md-3 col-lg-3">
                            <div className="div-apropos">
                                <h3 className="contactApropos">CONTACTS<div className="underline"></div></h3>
                                <div className="div-apropos-link">
                                  
                                    <div className="linkp">
                                        <FaPhoneAlt size={20}/>
                                        <span>+5906 90 45 07 04</span>
                                    </div>
                                    <div className="linkp">
                                        <IoIosMail size={22}/>
                                        <span>contact@imb.bio</span>
                                    </div>
                                    <div className="linkp">
                                        <FaMapMarkerAlt size={22}/>
                                        <span>IMB Section Siblet 97112 Grand-Bourg</span>
                                    </div>
                                </div>
                                <img className="logo-footer" src={logo} alt="logo" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-5 col-lg-5">
                            <h3>À PROPOS <div className="underline"></div></h3>
                            <div className="link">
                                <div style={{ textAlign: "justify" ,display: 'block'}}>
                                    <strong>IMB</strong> est une proposition de l’association « <strong>Rivière des Sources</strong> » pour nous permettre de valoriser la petite production locale amateur ou professionnelle à travers une mise en contact directe des acheteurs et des producteurs.<br/>
                                    <br/>
                                    <strong>En tant que consommateur</strong> je peux visualiser les lieux de productions qui me conviennent à travers la carte interactive et passer mes commandes en 3 clics. Le paiement se fait quand je récupère mes produits, soit : <br/>
                                        &nbsp;-&nbsp; sur le lieu de production <br/>
                                        &nbsp;-&nbsp; à domicile si la livraison est proposée par le producteur <br/>
                                        &nbsp;-&nbsp; ou tout autre lieu convenu entre le producteur et le client. <br/>
                                        <br/>
                                    <strong>En tant que producteur</strong> mes produits peuvent provenir d’un simple arbre qui me donne trop de fruits durant la saison, de mon petit jardin créole ou de ma petite exploitation agricole. 
                                      J’ajoute mes produits sur mon espace de vente et je contacte mes clients dès que je reçois une notification de commande par SMS. Seul les agriculteurs n’utilisant pas de traitements de synthèse peuvent ouvrir leur boutique sur IMB, pour plus d’info <a href="#" style={{ color: "#337ab7" }}>cliquez-ci</a>.
                                    <br/><br/>
                                    <p style={{textAlign: "center"}}>
                                        <a href="https://fr.freepik.com/vecteurs/nourriture" target="_blank" style={{ color: "#337ab7" }}>
                                            Illustration par jemastock - freepik.com
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container-apropos col-md-3 col-lg-3">
                            <div className="div-apropos">
                                <h3>RESTEZ CONNECTÉ<div className="underline"></div></h3>
                                <div className="social-media-link" style={{ }}>
                                    <div className="footer-link" >
                                            <FaFacebook size={25} style={{ color: '#1877f2' }} />
                                            {/* <img src={fb} alt="facebook"/> */}
                                        <a href="https://www.facebook.com/IMB-113790217742656/?view_public_for=113790217742656" target="_blank" >
                                            <span>Facebook</span>
                                        </a>
                                    </div>
                                    <div className="footer-link">
                                        {/* <img src={twitter} alt="twitter"/> */}
                                        <FaTwitter size={25} style={{ color: '#1da1f2' }} />
                                        <span>Twitter</span>
                                    </div>
                                    <div className="footer-link">
                                        {/* <img src={linked_in} alt="linked_in"/> */}
                                        <FaLinkedin size={25} style={{ color: '#0077b5' }} />
                                        <span>Linked In</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="end">
                    <span className="text-end"><strong>© {currentYear} In My Bush</strong> --- Tous Droits Réservés</span>
                 </div>
            </div>
        );
}
export default Footer;