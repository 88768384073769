import React , {useState, useEffect} from 'react'
import CategorieForm from './CategorieForm'
import CategorieEdit from './CategorieEdit'

import {Accordion} from 'react-bootstrap'
import CategorieListe from './CategorieListe';


function validate(event){
    if(event.target.validity.valueMissing){
        event.target.setCustomValidity("Veuillez remplir ce champ");
    }else{
        event.target.setCustomValidity("");
    }
    return true
}
const urlCategorie = process.env.REACT_APP_BACKEND_URL+"/categorie"

export default function Categorie( ){
    
    const [active1, setActive1] = useState(false)
    const [active2, setActive2] = useState(false)
    const [active3, setActive3] = useState(<CategorieListe urlCategorie={urlCategorie} />)
    return(
        <>
            <div className="container-admin-categorie">
                <Accordion  defaultActiveKey="2">
                    <div className="div-categorie-accordion-toogle">
                        <Accordion.Toggle
                            onClick={() => {setActive1(!active1); setActive2(false);setActive3(false)}}
                            className={(active1) ? "btn-toogle active" : "btn-toogle"}
                            variant="link"
                            eventKey="0"
                            style={{borderRadius: "5px 0 0 5px"}}
                        >
                            Ajout
                        </Accordion.Toggle>
                        <Accordion.Toggle
                            onClick={() => {setActive3(!active3); setActive1(false);setActive2(false)}}
                            className={(active3) ? "btn-toogle active" : "btn-toogle"}
                            variant="link"
                            eventKey="2"
                        >
                            Liste
                        </Accordion.Toggle>
                        <Accordion.Toggle
                            onClick={() => {setActive2(!active2); setActive1(false);setActive3(false)}}
                            className={(active2) ? "btn-toogle active" : "btn-toogle"}
                            variant="link"
                            eventKey="1"
                            style={{borderRadius: "0 5px 5px 0"}}
                        >
                            Modification
                        </Accordion.Toggle>
                    </div>
                    <div className="div-categorie-accordion-collapse">
                        <Accordion.Collapse eventKey="0">
                            <CategorieForm />
                        </Accordion.Collapse>
                        
                        <Accordion.Collapse eventKey="1">
                            <CategorieEdit urlCategorie={urlCategorie} />
                        </Accordion.Collapse>

                        <Accordion.Collapse eventKey="2">
                            <CategorieListe urlCategorie={urlCategorie} />
                        </Accordion.Collapse>
                    </div>
                </Accordion>
            </div>
        </>
    )
}