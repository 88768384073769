import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import ReactDropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCamera } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert'
import Axios from 'axios'
import $, {trim} from 'jquery'

const requireAsterix = <span style={{ color: "rgb(248, 133, 1)" }}>*</span>;
const URLBack = process.env.REACT_APP_BACKEND_URL;

    
function validate(event){
    if(event.target.validity.valueMissing){
        event.target.setCustomValidity("Veuillez remplir ce champ");
    }else{
        event.target.setCustomValidity("");
    }
    return true
}


export default function CategorieForm(){
    const user = useSelector(state => state.logger)
    const [Photos, setPhotos] = useState([])
    const [test, setTest] = useState(false)
    const [name, setName] = useState("")
    const [errorPhoto, setErrorPhoto] = useState({
        valid: false,
        messageError: ""
    })

    const onDropRejectedFile = (arg) => {
        let errors = arg[0].errors;
        let message = [];
        errors.forEach(error => {
            if (error.code === "file-invalid-type") {
                message.push(<span className="dangerMessageForm">Le fichier doit être une image.</span>);
            } else if (error.code === "too-many-files") {
                message.push(<span className="dangerMessageForm">Un fichier à la fois</span>);
            } else {
                message.push(<span className="dangerMessageForm">{error.message}</span>);
            }
        });
        setErrorPhoto({
            valid: true,
            messageError: message
        })
    }
    
    const onDropFile = (files) =>{
        var tempFiles = [];
    
        for(let i=0; i < files.length; i++){
            var photo = Object.assign(files[i], {
            preview: URL.createObjectURL(files[i])
        });
            tempFiles.push(photo);
        }
        setErrorPhoto({
            valid: false,
            messageError: ""
        })
        setPhotos(Photos => Photos.concat(tempFiles))
        setTest(true)
    }
    
    const deletePhoto = (key) =>{
        let CopyPhotos = [...Photos];
        let tempArray = [];
        for (var i = 0; i < CopyPhotos.length; i++) {
            if (i !== key) {
                tempArray.push(CopyPhotos[i]);
            }
        }
        setPhotos(tempArray);
    }
    
    const handlerSubmitAdd = (event) =>{
        $("#btn-valid").val("Chargement...")
        event.preventDefault();
        if(Photos.length <= 0){
            swal({
                title: "Veuillez ajouter une photo!",
                icon: "error",
                button: {
                    text: "OK",
                    value: false
                }
            })
            $("#btn-valid").val("Ajouter")
        }else{
            const formData = new FormData();
            formData.append("designation", trim(name))
            Photos.forEach((image) => formData.append("images", image))
            Axios.post(URLBack + "/categorie/", formData,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Barer " + user.current_user.token
                }
            }).then((res) => {
                setPhotos([])
                if (res.data.success){
                    swal({
                        title: "Ajout réussie.",
                        icon: "success",
                        button: {
                            text: "OK",
                            value: true
                        }
                    })
                    setTest(false)
                    $("#input-valid").val("")
                    $("#btn-valid").val("Ajouter")
                }else{
                    swal({
                        title: "Ce nom existe déjà !",
                        icon: "error",
                        button: {
                            text: "OK",
                            value: true
                        }
                    })
                    setTest(false)
                    $("#input-valid").val("")
                    $("#btn-valid").val("Ajouter")
                }

            })
        }    
    }


    return(
    
       <div className="form-categorie">
           
            <div className="container-image-upload">
                {
                    (test) ? 
                    (Photos.map((photo, key) => (
                        <div key={key} className="w3-col s3 imgContainer" style={{ zIndex: "100", borderRadius: "100%" }}>
                            <div className="w3-display-topmiddle imageContent-categorie catForm bord-dash bord-dash-orange" style={{ borderRadius: "100%" }}>
                                <img src={photo.preview} style={{ width: "100%", borderRadius: "100%" }} className="addForm imgDrop" alt={"image_" + key} />
                                <div className=" deletePhoto-categorie w3-display-bottomright" onClick={() => deletePhoto(key)}><FontAwesomeIcon style={{ marginTop: 2 }} icon={faTimes} /></div>
                            </div>
                        </div>
                    ))) : <></>
                }
                <ReactDropzone
                    multiple={false}
                    onDropRejected={(arg) => onDropRejectedFile(arg)}
                    onDrop={(files) => onDropFile(files)}
                    accept="image/*">
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()}/>
                            <div style={{ borderRadius: "100%" }} className="w3-display-container catForm imageContent-categorie  bord-dash bord-dash-gray">
                                <div className="w3-display-middle">
                                    <span style={{
                                        fontSize: 30,
                                        opacity: 0.8,
                                        color: "white"
                                    }}><FontAwesomeIcon icon={faCamera} /></span>
                                </div>
                            </div>
                        </div>
                    )}
                </ReactDropzone>

            </div>
            
            {(errorPhoto.valid) ? (<><br/><center>{errorPhoto.messageError}</center></>) : null}
            <form className="container-input-form"  onSubmit={(event) => handlerSubmitAdd(event)}>
                <input
                    id="input-valid"
                    className="input-categorie-form"
                    type="text" placeholder="ex: Animaux"
                    required
                    onInvalid={validate}
                    onInput={validate}
                    onKeyUp={(e) => setName(e.currentTarget.value)}
                />
                <input type="submit" id="btn-valid" value="Ajouter" />
           </form>
       </div>
    )
}