import React, {useState, useEffect} from 'react'
import {Accordion, Table} from 'react-bootstrap'
import Axios from 'axios'
import swal from 'sweetalert'
import $ from 'jquery'
import * as BiIcons from 'react-icons/bi'
import { useSelector } from 'react-redux';

import '../../css/user.css'

let urlAdmin = process.env.REACT_APP_BACKEND_URL+"/admin/"; 
let urlSuper = process.env.REACT_APP_BACKEND_URL+"/admin/getSuperAdmin"

export default function Admin() {

    const adminCurrent = useSelector(state => state.logger.current_user);
    // let id_current_admin = ""
    const [refresh, setRefresh] = useState()
    const [condition, setCondition] = useState(false)
    const [email, setEmail] =  useState("")

    const [allAdmin, setAllAdmin] = useState([])

    const handlerDelete = (event, index, key) => {
        event.preventDefault();
        $(`input[id=btn-delete-admin][name=${key}]`).val("Chargement...")
        swal({
            title: "Confirmation",
            text: "Voulez-vous vraiment supprimer cet utilisateur ?",
            icon: "warning",
            buttons: {
                cancel: "Annuler",
                confirm: "Confirmer"
            },
            dangerMode: true,
        }).then((result) => {
            if(result === true){
                Axios.delete(process.env.REACT_APP_BACKEND_URL+"/admin/deleteAdmin/"+index).then((response) => {
                    if(response.data.success){
                        swal({
                            icon: "success",
                            title: "Supprimé!",
                            text: "L'utilisateur a été bien supprimé."
                        }).then(() => {
                            $(`input[id=btn-delete-admin][name=${key}]`).val("Supprimer")
                            setRefresh({})
                        })
                    }else{
                        swal({
                            icon: "error",
                            title: "Erreur",
                            text: "Une erreur s'est produit, veuillez réessayer ultérieurement."
                        }).then(() => {
                            $(`input[id=btn-delete-admin][name=${key}]`).val("Supprimer")
                            setRefresh({})
                        })
                    }
                })
            }else   $(`input[id=btn-delete-admin][name=${key}]`).val("Supprimer")

        })
    }

    const handlerSubmit = (e) => {
        e.preventDefault();
        $("#btn-admin-envoie").val("Chargement...")
        Axios.post(process.env.REACT_APP_BACKEND_URL+"/admin/sendMailCreate", {email: email}).then((response) => {
            if(response.data.result){
                swal({
                    icon: "success",
                    title: "Envoyé!",
                    text: "Un lien spécifique a été envoyé à cette adresse e-mail.",
                    buttons: "OK"
                }).then(() => {
                    $("#btn-admin-envoie").val("Envoyer")
                    setRefresh({})
                })
            }else{
                swal({
                    icon: "error",
                    title: "Erreur",
                    text: "Une erreur s'est produit, veuillez réessayer ultérieurement."
                }).then(() => {
                    $("#btn-admin-envoie").val("Envoyer")
                    setRefresh({})
                })
            }
        })
    }

    async function fetchAdmin(){
        const response = await Axios.get(urlAdmin)
        setAllAdmin(response.data.allAdmin);
    }
    async function fetchSuperAdmin(){
        const response = await Axios.get(urlSuper);
        response.data.result.map((super_admin) => {
            if(super_admin._id === adminCurrent.id){
                setCondition(true)
            }else{
                setCondition(false)
            }
        })
    }

    useEffect(() => {
        let mounted = true
        if(mounted){
            fetchAdmin();
            fetchSuperAdmin();
        }
        return () => {
            mounted = false
        }
    }, [urlAdmin, urlSuper, refresh])


    return (
        <div className="container-administrateur">
            <div className="table-responsive">
                <div className="div-add-admin-btn">
                    <Accordion className="d-flex flex-row-reverse justify-content-start">
                        <Accordion.Toggle
                            id="btn-addAdmin"
                            variant="link"
                            eventKey="0"
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            <BiIcons.BiUserPlus id="icon-add"/>
                            <span style={{margin: "10px"}}>Ajouter un administrateur</span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0" style={{width: "100%", height: "100%"}}>
                            {(condition)
                            ?
                            (<>
                                <div>
                                    <center><p>Pour ajouter un <strong>administrateur</strong>, on lui envoie un e-mail pour affirmer qui'il voudrait s'inscrire en tant qu' <i>administrateur</i>.</p></center>
                                </div>
                                <div className="div-add-admin-form" onSubmit={(e) => handlerSubmit(e)}>
                                    <form align="center">
                                        <input id="input-admin-email" required type="email" onChange={(e) => setEmail(e.currentTarget.value)}/>
                                        <input id="btn-admin-envoie" type="submit" value="Envoyer"/>
                                    </form>
                                </div>
                            </>)
                            :
                            (
                                <center>
                                    <h5 style={{width: "fit-content"}}>Vous n'êtes pas un <i>Super-administrateur</i></h5>
                                </center>
                            )}
                        </Accordion.Collapse>
                    </Accordion>
                </div>
                <div className="container-table-administrateur">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nom d'utilisateur</th>
                            <th>Nom et Prénom</th>
                            <th>e-mail</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allAdmin.map((admin, key) => {
                                return(
                                    <tr key={key}>
                                        <td><strong>{key+1}</strong></td>
                                        <td>{admin.username}</td>
                                        <td>{admin.nom} {admin.prenom}</td>
                                        <td>{admin.email}</td>
                                        {
                                            (condition)
                                            ?
                                            (<td><center><input id="btn-delete-admin" name={key} type="submit" value="Supprimer" onClick={(event) => {handlerDelete(event, admin._id, key)}} /></center></td>)
                                            :
                                            (<td><center><input id="btn-delete-admin" name={key} type="submit" value="Supprimer" disabled/></center></td>)
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>

                </Table>
                </div>
            </div>
        </div>
    )
}