import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import AnnonceData from "./annonceData";
import fonction from "../../../components/function/function";
import "../../css/annonce.css";
import Pagination from "../Pagination";
import * as annonceService from "../../../services/annonce";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const url = process.env.REACT_APP_BACKEND_URL;

export default function Annonce() {
  const myFonction = new fonction();
  const user = useSelector((state) => state.logger.current_user);

  const [annonceData, setAnnonceData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [PostsPerPage] = useState(5);

  //PagePerPost
  const indexOfLastPost = CurrentPage * PostsPerPage;
  const indexOfFirstPost = indexOfLastPost - PostsPerPage;
  const currentPosts = useMemo(() => {
    return convert(annonceData).slice(indexOfFirstPost, indexOfLastPost);
  }, [annonceData]);

  const UrlGetAnnonce = process.env.REACT_APP_BACKEND_URL + "/annonce";

  const getAnnonceSearch = async (e) => {
    setLoading(true);
    const result = await Axios.get(url + "/annonce?like=" + e.target.value);
    setAnnonceData(result.data.results);
    setLoading(false);
  };

  function convert(data) {
    let tab = [];
    data.map((item, index) => {
      const obj = {
        _id: item._id,
        indexMap: index,
        categorie: item.categorie,
        city: item.city,
        title: item.title,
        disponibility: {
          debut: myFonction.changeDates(item.disponibility.debut),
          fin: myFonction.changeDates(item.disponibility.fin),
        },
        email: item.email,
        etatStock: item.stockStatus,
        stockStatus: item.stockStatus, 
        images: item.images,
        price: item.price,
        createdAt: myFonction.changeDates(item.createdAt),
        telephone: item.telephone,
        user: item.user,
        user_detail: item.user_detail,
      };
      tab.push(obj);
    });
    return tab;
  }

  async function deleteAnnonce(id) {
    const result = await swal({
      title: "Confirmation",
      text: "Voulez-vous vraiment supprimer cette annone ?",
      icon: "warning",
      buttons: {
        cancel: "Annuler",
        confirm: "Confirmer",
      },
      dangerMode: true,
    });
    if (result) {
      try {
        await annonceService.deleteAnnonce(id, {
          authorization: `Bearer ${user.token}`,
        });
        setAnnonceData((prev) => prev.filter((a) => a._id !== id));
        swal({
          icon: "success",
          title: "Supprimé!",
          text: "L'utilisateur a été bien supprimé.",
        });
      } catch (error) {
        swal({
          icon: "error",
          title: "Erreur",
          text: "Une erreur s'est produit, veuillez réessayer ultérieurement.",
        });
      }
    }
  }

  useEffect(() => {
    Axios.get(UrlGetAnnonce).then((result) => {
      setAnnonceData(result.data.results);
      setLoading(false);
    });
  }, [UrlGetAnnonce]);

  return (
    <>
      <div className="container-admin-annonce">
        {/* <h3 align="center" className="sectionTitle">ANNONCES</h3> */}
        <div className="div-admin-search">
          <input onChange={(e) => getAnnonceSearch(e)} className="admin-search" type="text" placeholder="Recherche" />
        </div>
        <div className="div-admin-annonce w-100">
          <AnnonceData deleteAnnonce={deleteAnnonce} Data={currentPosts} Loading={Loading} />
        </div>
        <Pagination dataLength={annonceData.length} postPerPage={PostsPerPage} paginate={(number) => setCurrentPage(number)} />
      </div>
    </>
  );
}
