export const loggerUserReducer = (state = null, action) => {
    switch (action.type) {
        case 'LOGGING_USER':
            return {
                current_user: {
                    role: action.payload.role,
                    id: action.payload.id,
                    token: action.payload.token             
                }
            };
        case 'LOGGOUT_USER':
            return null;
        default:
            return state;
    }
}