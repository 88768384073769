import React from 'react'
import Router_admin from './RouterAdmin'
import Header from './components/Header'
import SideBar from './components/SideBar'
import './css/index.css'

export default function IndexA() {

    return (
        <>
            {/* <Header/> */}
            <div className="" id="container-admin" >
                <div className="container-admin-content" style={{ minHeight: '100vh' }}>
                    <div className="" 
                        style={{ 
                            backgroundColor: 'darkslategrey',
                            color: 'white',
                            height: '100%',
                            paddingLeft: '25px',
                            position: 'fixed',
                            width: '200px', // Définir la largeur du premier div
                        }}>
                        <SideBar />
                    </div>
                    <div className="div-admin-route" style={{ marginLeft: '200px' , marginTop : '10px', padding : '0px 100px' }}> {/* Ajuster la marge gauche en fonction de la largeur du premier div */}
                        {(window.location.pathname === "/admin/dashboard") ? <></> : <Router_admin />}
                    </div>
                </div>

            </div>            
        </>
    )
}

