import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import Login from './components/Login'
import IndexAdmin from './indexA'
import PrivateRoute from '../security/adminPrivateRoute'


//const Produit = React.lazy(() => import ("./components/produit"))


export default function RouterAdmin() {

    return (
       
            <Switch>
                <Route exact path="/admin" component={() => <Redirect to='/admin/dashboard/annonce'/>} />
                <Route exact path="/admin/dashboard" component={() => <Redirect to='/admin/dashboard/annonce'/>} />
                <PrivateRoute path="/admin/dashboard" component={IndexAdmin}/>
                <Route exact path="/admin/login" component={Login}/>
                <Route component={() => <div>error 404</div>} />
            </Switch>
        
    )
}
