import axios from "./axios";

export const deleteAnnonce = async (id, options) => {
  const response = await axios.delete(`annonce/${id}`, {
    headers: {
      "Authorization": options.authorization
    }
  });
  return response.data;
};
