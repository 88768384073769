import React, { Suspense } from "react";
import reactDOM from "react-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//redux
import { createStore } from "redux";
import { Provider } from "react-redux";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from "redux-persist/integration/react";
import reducers from "./my_redux/reducers/";

//css
import 'rsuite/dist/styles/rsuite-default.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/app.css";
import "leaflet/dist/leaflet.css";

import IndexAdmin from './admin/router_manager'

const Index = React.lazy(() => import("./views/Index"));

//redux
const persistConfig = {
    key: 'root',
    storage
}

//alert 
const options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER,
    transition: transitions.SCALE,
    offset: "30px"
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const persitore = persistStore(store);

class App extends React.Component {

    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persitore}>
                    <Suspense fallback={null}>
                        <AlertProvider template={AlertTemplate} {...options}>
                            <Router>
                                <Switch>
                                    <Route path="/admin" component={IndexAdmin} />
                                    <Route path="/" component={Index} />
                                    <Route component={() => <div>error 404</div>} />
                                </Switch>
                            </Router>
                        </AlertProvider>
                    </Suspense>
                </PersistGate>
            </Provider>
        );
    };
}

const rootElement = document.getElementById("root");
reactDOM.render(<App />, rootElement);