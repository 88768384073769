import React, {useState, useEffect} from 'react';
import  {useSelector} from 'react-redux';
import * as FaIcons from "react-icons/fa";
import swal from 'sweetalert';
import Axios from 'axios';
import $, {trim} from 'jquery';

const requireAsterix = <span style={{ color: "rgb(248, 133, 1)", alignSelf:"flex-start" }}>*</span>;
const URLBack = process.env.REACT_APP_BACKEND_URL;

function validate(event){
    if(event.target.validity.valueMissing){
        event.target.setCustomValidity("Veuillez remplir ce champ");
    }else{
        event.target.setCustomValidity("");
    }
    return true
}

export default function SousCategorieForm(props){
    const user = useSelector(state => state.logger);
    const [name, setName] = useState("")
    const [allCategorie, setAllCategorie] = useState([]);
    const [categorieSelected, setCategorieSelected] = useState("Choisissez une catégorie");
    const [showListCategorie, setShowListcategorie] = useState(false);
    const [categorieInfo, setCategorieInfo] = useState({
        image: "",
        designation: ""
    })

    const setCategorie = (cat) => {
        setCategorieSelected(cat.designation);
        setCategorieInfo({
            image: URLBack+"/images/"+cat.photo,
            designation: cat.designation
        })
    }

    const getAllCategorie = () => {
        const categories = JSON.parse(localStorage.getItem("categories")).allCategorie;
        setAllCategorie(categories);
    }
    useEffect(() => {
        getAllCategorie();
    },[])

    const handlerSubmitAdd = (event) =>{
        $("#btn-valider").val("Chargement...")
        event.preventDefault();
        if(categorieSelected==="Choisissez une catégorie"){
            swal({
                title: "Veuillez choisir une catégorie!",
                icon: "error",
                button: {
                    text: "OK",
                    value: false
                }
            })
            $("#btn-valider").val("Ajouter")
        }
        else{
            const formData = new FormData();
            formData.append("designation", trim(name))
            formData.append("categorie", categorieSelected);

            const newProd = {
                designation: trim(name),
                categorie: categorieSelected
            }

            Axios.post(URLBack + "/produit/", newProd, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Barer " + user.current_user.token
                }
            }).then((res) => {
                if (res.data.success){
                    swal({
                        title: "Ajout réussie.",
                        icon: "success",
                        button: {
                            text: "OK",
                            value: true
                        }
                    })
                    $("#input-valid").val("")
                    $("#btn-valider").val("Ajouter")
                    setCategorieInfo({...categorieInfo, image:""})
                    setCategorieSelected("Choisissez une catégorie")
                }else{
                    swal({
                        title: "Ce nom existe déjà ",
                        icon: "error",
                        button: {
                            text: "OK",
                            value: true
                        }
                    })
                    $("#input-valid").val("")
                    $("#btn-valider").val("Ajouter")
                }
            })
        }    
    } 

    return(
       <div className="form-sousCategorie">
            <div className="dropdownCategorie">
                <div className="dropbtnCategorie" onClick={()=> showListCategorie?setShowListcategorie(false):setShowListcategorie(true)}>
                    <div>{categorieSelected}{requireAsterix}</div>
                    <div className="btnToDrop"><FaIcons.FaChevronDown /></div>
                </div>
                {
                (showListCategorie)
                    ?
                    (
                        <div className="dropdownCategorie-content"> 
                        {
                            allCategorie.map((cat, key) => (
                                <div key={key} className="oneCategorie" onClick={() => {setCategorie(cat); setShowListcategorie(false)}}> <img src={URLBack+"/images/"+cat.photo} alt={cat.photo} width="50px" height="50px"/><span className="designation"> {cat.designation}</span></div>
                            ))
                        }
                        </div>
                    )
                    : <></>    
                }
            </div>
            <div className="categorieFound">
                {
                    categorieInfo.image===""?(
                        <div className="aucuneCategorieSelect">aucune catégorie sélectionnée</div>
                    ):(
                        <div className="theCategorie">
                            <img src={categorieInfo.image} alt="catImage" width="70px" height="70px"/>
                            <p className="designation">{categorieInfo.designation}</p>
                        </div>  
                    )
                } 
                <form className="container-input-form-SC"  onSubmit={(event)=>handlerSubmitAdd(event)}>
                    <div className="d-flex flex-row">
                        <input
                            id="input-valid"
                            className="form-control input-categorie-form"
                            type="text"
                            placeholder="ex: Volaille"
                            required
                            onInvalid={validate}
                            onInput={validate}
                            onKeyUp={(e) => setName(e.currentTarget.value)}
                        />
                        {requireAsterix}
                    </div>
                    <input type="submit" className="btnSC" id="btn-valider" value="Ajouter" />
                </form>   
            </div>
       </div>
    )
}