import React from "react";
import { Route, Redirect } from "react-router-dom";
import {useSelector} from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {

    const admin = useSelector(state => state.logger);
    localStorage.setItem('lastUrlAdmin', rest.location.pathname);
    return(
    <Route {...rest} render={(props) => (
            admin!==null
            ? (admin.current_user.role === "admin" || admin.current_user.role ===   "super_admin" ?<Component {...props} />:<Redirect to='/' />)
            : <Redirect to='/admin/login' />
    )} />
)};

export default PrivateRoute;