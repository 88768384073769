import React, { useEffect, useState } from 'react';
import { Nav,Accordion} from 'react-bootstrap';
import SousCategorieForm from './SousCategorieForm';
import SousCategorieEdit from './SousCategorieEdit';
import SousCategorieListe from './SousCategorieListe';

const urlGet = process.env.REACT_APP_BACKEND_URL+'/produit'

function SousCategorie() {
        
    const [active1, setActive1] = useState(false)
    const [active2, setActive2] = useState(false);
    const [active3, setActive3] = useState(<SousCategorieListe urlToGet={urlGet}/>);
    let render = false;
    return(
        <>
            <div className="container-admin-categorie">
            <Accordion  defaultActiveKey="2">
                    <div className="div-categorie-accordion-toogle">
                        <Accordion.Toggle
                            onClick={() => {setActive1(!active1); setActive2(false);setActive3(false)}}
                            className={(active1) ? "btn-toogle active" : "btn-toogle"}
                            variant="link"
                            eventKey="0">
                            Ajout
                        </Accordion.Toggle>
                        <Accordion.Toggle
                            onClick={() => {setActive3(!active3); setActive1(false);setActive2(false)}}
                            className={(active3) ? "btn-toogle active" : "btn-toogle"}
                            variant="link"
                            eventKey="2"
                        >
                            Liste
                        </Accordion.Toggle>
                        <Accordion.Toggle
                            onClick={() => {setActive2(!active2); setActive1(false);setActive3(false)}}
                            className={(active2) ? "btn-toogle active" : "btn-toogle"}
                            variant="link"
                            eventKey="1">
                            Modification
                        </Accordion.Toggle>
                    </div>
                    <div className="div-categorie-accordion-collapse">
                        <Accordion.Collapse eventKey="0">
                            <SousCategorieForm />
                        </Accordion.Collapse>
                        
                        <Accordion.Collapse eventKey="1">
                            <SousCategorieEdit urlToGet={urlGet}/>
                        </Accordion.Collapse>

                        <Accordion.Collapse eventKey="2">
                            <SousCategorieListe urlToGet={urlGet}/>
                        </Accordion.Collapse>
                    </div>
                </Accordion>
            </div>
        </>
    )
}

export default SousCategorie