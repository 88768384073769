import {loggerUserReducer} from "./loggerUserReducer";
import {tempReducer} from "./tempReducer";

import { combineReducers } from "redux";

const allReducers = combineReducers({
    logger: loggerUserReducer,
    temp: tempReducer
});

export default allReducers;