import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../css/user.css'
import { Accordion } from "react-bootstrap";
import UserList from './utilisateur'
import AdminList from './admin'
import Axios from 'axios'

export default function User() {

    const [active1, setActive1] = useState(false)
    const [active2, setActive2] = useState(false)
    
    return (
 
        <div className="container-admin-user">
            {/* <h3 align="center" className="sectionTitle">G-USERS</h3> */}
            <div className="container-admin-user-accordion">
                <Accordion>
                    <div className="accordion-toogle-admin-users">
                        <Accordion.Toggle
                            variant="link"
                            eventKey="0"
                            onClick={() => {setActive1(!active1); setActive2(false)}}
                            className={(active1) ? "toogle-admin-user active" : "toogle-admin-user"}

                        >Liste des utilisateurs</Accordion.Toggle>
                        <Accordion.Toggle
                            variant="link"
                            eventKey="1"
                            onClick={() => {setActive2(!active2); setActive1(false)}}
                            className={(active2) ? "toogle-admin-user active" : "toogle-admin-user"}

                        >Liste des administrateurs</Accordion.Toggle>
                    </div>
                    <div className="accordion-admin-collapse-users">
                    <Accordion.Collapse eventKey="0">
                        <UserList />
                    </Accordion.Collapse>
                    
                    <Accordion.Collapse eventKey="1">
                        <AdminList />
                    </Accordion.Collapse>
                    </div>
                </Accordion>
            </div>
        </div>


    );
}