import React from 'react'
import Footer from '../../views/layout/footer/footer'
import Header from './Header'
import Axios from 'axios'
import {useDispatch} from 'react-redux'

// import {loginAdmin} from '../myRedux/actions/loginAdmin';
import {loggingUser} from '../../my_redux/actions/logginUser'
import { useState } from 'react'
import $ from "jquery";
import '../css/reset.css'
import {trim} from 'jquery'
import swal from 'sweetalert'

const requireAsterix = <span style={{ color: "rgb(248, 133, 1)" }}>*</span>;


export default function Login() {
    
    const dispatch = useDispatch();

    const [errorMsg, setErrorMsg] = useState("");
    const [emailOrPseudo, setEmailOrPseudo]= useState("");
    const [password, setPassword]= useState();

    const handlerSubmit = (e) => {
        
        e.preventDefault();
        $("#submitBtn").val("Chargement...");
        fetch(process.env.REACT_APP_BACKEND_URL+'/admin/checkRole/'+trim(emailOrPseudo)).then((response)=>{
            let role
            response.json().then((result) => {
                role = result.response
                Axios.post(process.env.REACT_APP_BACKEND_URL + "/user/login?role="+role, {
                    username: emailOrPseudo,
                    email: emailOrPseudo,
                    password: password
                }).then((res) => {
                    dispatch(loggingUser({
                        username: res.data.success.username, 
                        id: res.data.success.id,
                        role: res.data.success.role,
                        token: res.data.success.token,
                        name: res.data.success.name
                    })
                    );            
                    window.location.assign(localStorage.getItem('lastUrlAdmin') ? localStorage.getItem('lastUrlAdmin') : "/admin/dashboard");
                    
                }).catch((err) => {
                        if (err.message.indexOf('401') > 0) {
                            setErrorMsg("Nom d'utilisateur ou mot de passe incorrect.");
                            $("#submitBtn").val("Connexion");
                            $(".errorContent").fadeIn();
                            console.log(err)
                        } else {
                            swal({
                                icon: "error",
                                title: "Erreur:",
                                text: err.message,
                                text: "Veuillez verifier votre accès à l'internet.",
                                button: {
                                    text: "OK"
                                }
                            });
                            $("#submitBtn").val("Connexion");
                        }
                    });

            })
        })        
    }

    return (
        <>
            <Header/>
            <div className="container" style={{marginBottom: "4em"}}>
                <br/>
                <div className="d-flex flex-column justify-content-center align-center">
                    <div className="login-content">
                        <h1 className="my_title_1"  style={{textAlign: 'center'}} align='center'>CONNEXION</h1>
                    </div>
                    <div className="container-login">
                        <div className="errorContent" style={{ display: "none" }}>{errorMsg}</div>
                        <div className="body-content" align='center'>
                            <form id="loginForm" onSubmit={(e) => handlerSubmit(e) }>
                                <label className="myLabel" form="mailOrUsername">Adresse mail ou Pseudo{requireAsterix}</label><br />
                                <input
                                    type="text" required id="mailOrUsername"
                                    placeholder="Email ou Pseudo" 
                                    onChange={(event) => setEmailOrPseudo(event.target.value)}
                                    className="my-form-control"
                                />
                                <label className="myLabel" form="password">Mot de passe{requireAsterix}</label><br />
                                <input
                                    type="password" required id="password"
                                    placeholder="Mot de passe"
                                    onKeyUp={(event) => setPassword(event.currentTarget.value)}
                                    className="my-form-control"
                                />
                                <div className="w3-display-container" style={{ marginBottom: 40 }}>
                                    <div className="w3-display-right">
                                        <a href="/reset/sendMail" style={{ fontSize: "small" }}>Mot de passe oublié ?</a>
                                    </div>
                                </div>
                                <input
                                    type="submit" className="my-btn-form c-green"
                                    id="submitBtn" value="Connexion"
                                />
                            </form>
                        </div>	 
                    </div>
                </div>
            </div><br/>
            <Footer/>
        </>
    )
}


