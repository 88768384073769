import React, { useEffect } from 'react'
import * as AiIcons from 'react-icons/ai'
import * as FaIcons from 'react-icons/fa'
import {Nav} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import '../css/sidebar.css'
import { useState } from 'react'
import logo from "./Logo_InMyBush.png";
import { AiFillShopping } from 'react-icons/ai';
import { RiLogoutBoxRLine } from "react-icons/ri";
import { loggoutUser } from "../../my_redux/actions/logginUser";
import { useSelector, useDispatch } from "react-redux";
import '../css/header.css';
import Axios from "axios";



export default function SideBar() {

    const [link1, setLink1] = useState(false)
    const [link2, setLink2] = useState(true)
    const [link3, setLink3] = useState(true)
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_BACKEND_URL;
    const [loading, setLoading] = useState(true);
    const [currentAdminName,setCurrentAdminName] = useState('');
    const [photoUser, setPhotoUser] = useState("");
    const userLog = useSelector(state => state.logger);

    const checkPhotoUser = (photo) => {
        var link = url+"/images-get/"+photo;
        const default_pdp = "/image/avatar_default.png";
        var xhr = new XMLHttpRequest();
        xhr.open('GET', link);
        xhr.onreadystatechange = function() {           
            if (xhr.readyState === 4) {
                switch(xhr.status) {
                    case 200 :
                        setPhotoUser(link);
                    break;
                    case 404 :
                        setPhotoUser(default_pdp);
                    break;
                    case 402 :
                        setPhotoUser(link);
                    break;
                }
            }
        }; 
        xhr.send();
    }


    useEffect(()=>{
        try {
            const getCurrentUser = async (user)=>{
                const getUsername = await Axios.get(url + '/user/' + user.current_user.id);
                (getUsername.data.photo)?(checkPhotoUser(getUsername.data.photo)):(setPhotoUser("/image/avatar_default.png"));
                setCurrentAdminName(getUsername.data.username)
                setLoading(false);
                console.log("ok")
            }
                getCurrentUser(userLog);
        } catch (error) {
            alert(error.message);
            
        }

    },[])

    return (
        <>
            <Nav className="nav-menu">
                <div className="div-menu">


                <div className="logoHeaderAdmin">
                    <img src={logo} style={{
                        width: '7.5em',
                        height: '6em',
                        cursor: "pointer",
                        margin : '25px 0px'
                    }} onClick={()=>window.location.assign("/")} />
                </div>
                    
                    
                    <span className="menu-items">
                        <Link
                            onClick={() => 
                                {
                                    setLink1(false)
                                    setLink2(true)
                                    setLink3(true)
                                }
                            }
                            className={(link1) ? "menu-link" : "menu-link active"}
                            to="/admin/dashboard/annonce">
                            <div className="icon-sidebar"><AiIcons.AiFillSound style={{width:'25px', height:'25px'}} /></div>
                            <div className="title-sidebar">Annonces</div>
                        </Link>
                    </span>
                    <span className="menu-items">
                        <Link
                            onClick={() => 
                                {
                                    setLink2(false)
                                    setLink1(true)
                                    setLink3(true)
                                }
                            }
                            className={(link2) ? "menu-link" : "menu-link active"}
                            to="/admin/dashboard/produit">
                            <div className="icon-sidebar"> <AiFillShopping style={{width:'25px', height:'25px'}}/></div>
                            <div className="title-sidebar">Produits</div>
                        </Link>
                    </span>
                    <span className="menu-items">
                        <Link
                            onClick={() => 
                                {
                                    setLink3(false)
                                    setLink1(true)
                                    setLink2(true)
                                }
                            }
                            className={(link3) ? "menu-link" : "menu-link active"}
                            to="/admin/dashboard/user">
                            <div className="icon-sidebar"><FaIcons.FaUsers style={{width:'25px', height:'25px'}}/></div>
                            <div className="title-sidebar">Utilisateurs</div>
                        </Link>
                    </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' , alignSelf : 'end' , margin: '40px 0px' }}>
                    <hr style={{ width: '100%', margin: '0', borderTop: '1px solid white' }} />
                    <div 
                        className="title-sidebar menu-link-user" 
                        style={{cursor : 'pointer',display : 'flex', alignItems : 'center' , gap : '10px'}}
                        onClick={() => { window.location.assign("/mon_compte") }}>
                        <img 
                            src={photoUser} 
                            className="img-circle" 
                            style={{ width: 25, height: 25, objectFit: "cover", objectPosition: "center",
                            border: "1px solid grey", }} alt="avatar" />
                         {(currentAdminName)?currentAdminName:'Admin'}
                    </div>
                    <div 
                        className="title-sidebar menu-link-user"
                        style={{cursor : 'pointer' ,display : 'flex', alignItems : 'center', gap : '10px'  }}
                        onClick={() => {
                            setTimeout(() => {
                                dispatch(loggoutUser());
                                localStorage.removeItem("categories");
                                window.location.assign("/admin/login")
                            }, 1000);
                        }} >
                            <RiLogoutBoxRLine size={24}/> Déconnexion
                    </div>
                </div>

            </Nav>
        
        </>
    )
}
