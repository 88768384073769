import React from 'react'


const Pagination = (props) => {

    const pageNumbers = [];
    for(let i=1; i<=Math.ceil(props.dataLength / props.postPerPage); i++){
        pageNumbers.push(i)
    }
        return(
            <nav className="d-flex flex-row align-self-center">
            <ul className="pagination">
                {
                    pageNumbers.map((number) => {
                    return(
                        <li key={number} className="page-item" style={{cursor: 'pointer',background: "red"}}>
                            <a onClick={() => props.paginate(number)} className="page-link">{number}</a>
                        </li>
                    )
                    })
                }
            </ul>
            </nav>
        )
    }
export default Pagination 