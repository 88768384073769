import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { FaRegEnvelope, FaTimes } from "react-icons/fa";
import Axios from 'axios';
import swal from 'sweetalert'

const ModalToSubscribe = ({ show, setShow , emailNewLetter , setEmailNewLetter }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [erreur, setErreur] = useState('');
  const [ isLoading , setLoading ] = useState(false);

  const handleClose = () => {
    setErreur(''); 
    setEmailNewLetter('');
    setEmail('');
    setShow(false);
  };
  useEffect(()=>{
    setEmail(emailNewLetter)
  },[emailNewLetter]);

const handleSubscribe = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    setLoading(true);
    if (!email) {
        setErreur('Veuillez fournir une adresse e-mail.');
    } else if (!emailRegex.test(email)) {
        setErreur('Veuillez fournir une adresse e-mail valide.');
    } else {
        try {
          await Axios.post(process.env.REACT_APP_BACKEND_URL  + '/subscribe/', { email , name });
          swal({
            title: 'Félicitations!',
            text: 'Vous vous êtes abonné avec succès à la newsletter.',
            icon: 'success',
            timer: 5000,  
            showConfirmButton: false
          });
          setEmailNewLetter('');
        } catch (error) {
          setErreur(error.response.data.message);
          return setLoading(false);
        }
        setEmail('');
        setName('');
        setErreur('');
        handleClose();
    } 
    setLoading(false);     
};

useEffect(() => {
    const htmlElement = document.documentElement;

    if (show) {
        htmlElement.style.overflow = 'hidden'; 
        document.body.classList.add('modal-open');
      
    } else {
        htmlElement.style.overflow = '';    
        document.body.classList.remove('modal-open');
    }
  }, [show]);
  return (
   
    <div
        className="modal"
        style={{
            display: show ? 'flex' : 'none',
            alignItems: "center"
        }}
    >
       <Modal.Dialog>
        <Modal.Header style={{ backgroundColor: '#28a745', color: '#fff' , alignItems : "center" }}>
          <Modal.Title style={{width: "100%"}}>
             <FaRegEnvelope size={24} style={{ marginRight: "8px" }}/>  Inscription à la Newsletter
             </Modal.Title>

             <FaTimes size={20} style={{ cursor: "pointer" }} onClick={handleClose}/>
        </Modal.Header>

        <Modal.Body>
          <p>
            En vous abonnant à notre newsletter, vous resterez informé(e) de
            nos dernières actualités, offres spéciales et mises à jour
            exclusives.
          </p>
          <Form.Group controlId="formBasicNom">
            <Form.Label>Nom (facultatif)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entrez votre nom"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mb-2"
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Entrez votre adresse e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mb-2"
              required
              />
              {erreur && <p style={{ color: 'red' }}>{erreur}</p>}
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button onClick={handleSubscribe} style={{ background: "#28a745" }} disabled={isLoading}>
            {isLoading ? (
                <div
                  class="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "S'abonner"
              )}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

export default ModalToSubscribe;
