import React from 'react'
import { Switch, Route} from 'react-router'
import Annonce from './components/Annonce/Annonce'
import User from './components/Users/index'
import Produit from './components/Produit/index'
import PrivateRoute from '../security/adminPrivateRoute'


//const Produit = React.lazy(() => import ("./components/produit"))


export default function RouterAdmin() {

    return (
       
            <Switch>
                <PrivateRoute exact path="/admin/dashboard/annonce" component={Annonce}/>
                <PrivateRoute exact path="/admin/dashboard/produit" component={Produit}/>
                <PrivateRoute exact path="/admin/dashboard/user" component={User}/>
                <Route component={() => <div>error 404 PAGE NOT FOUND</div>} />
            </Switch>
        
    )
}
