import React, { useState } from "react";
import * as FaIcon from "react-icons/fa";
import * as IoIcon from "react-icons/io5";
import * as MdIcon from "react-icons/md";
import { Card, Spinner } from "react-bootstrap";
import { Modal } from "rsuite";
import "../../css/annonce.css";

const url = process.env.REACT_APP_BACKEND_URL;

function AnnonceData(props) {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState();
  const open = (key) => {
    setIndex(key);
    setShow(true);
  };
  const close = () => {
    setShow(false);
  };

  function showStockStatus(status) {
    const statusId = status === "À venir" ? "stockA" : status === "Hors stock" ? "stockF" : "stockV";
    return (
      <div className="stock" id={statusId}>
        {status.toUpperCase()}
      </div>
    );
  }

  if (props.Loading) {
    return (
      <div className="Spin">
        <Spinner className="align-self-center" animation="border" variant="warning" />
      </div>
    );
  }

  if (props.Data.length === 0) {
    return (
      <p
        style={{
          fontWeight: "bold",
          fontFamily: "Fredoka, sans-serif",
          textAlign: "center",
        }}
      >
        --- AUCUN PRODUIT TROUVÉ ---
      </p>
    );
  }

  return props.Data.map((item, key) => (
    <div className="card-container border border-1 w-100 mx-2 my-5 p-2 d-flex" key={key}>
      <div className="d-flex justify-content-center" style={{ flex: 1 }}>
        <img width={90} height={90} src={url + "/images/" + item.images[0]} alt="image" onClick={() => open(key)} />
        <Modal overflow={false} size="md" show={show} onHide={() => close()}>
          {key == index ? (
            <Modal.Body>
              <img className="image-annonce" style={{ maxHeight: "40em", maxWidth: "50em" }} src={url + "/images/" + item.images[0]} alt="image" />
            </Modal.Body>
          ) : (
            <></>
          )}
        </Modal>
      </div>
      <div className="d-flex flex-column justify-content-center" style={{ flex: 1 }}>
        <span className="titleAnnonce">{item.title}</span>
        <span className="price">{item.price}€</span>
        <span className="card-subtitle">{item.createdAt}</span>
      </div>
      <div className="d-flex flex-column justify-content-center" style={{ flex: 2 }}>
        <span>Période de disponibilité: </span>
        <span style={{ fontSize: "0.95em", marginTop: "2px" }}>
          {item.disponibility.debut} - {item.disponibility.fin}
        </span>
      </div>
      <div className="d-flex flex-column justify-content-center" style={{ flex: 2 }}>
        <span>
          <FaIcon.FaHome className="icons mr-2" />
          {item.city}
        </span>
        <span>
          <IoIcon.IoCallSharp className="icons mr-2" />
          {item.user_detail.phone}
        </span>
        <span>
          <MdIcon.MdMail className="icons mr-2" />
          {item.user_detail.email}
        </span>
      </div>
      <div className=" d-flex align-items-center justify-content-center border-left ml-1" style={{ flex: 1 }}>
        <span>{showStockStatus(item.stockStatus)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-center border-left" style={{ flex: 1 }}>
        <button className="delete-button" type="button" onClick={() => props.deleteAnnonce(item._id)}>
          Supprimer
        </button>
      </div>
    </div>
  ));
}

export default AnnonceData;
