import React, { useEffect } from 'react'
import { useState } from 'react'
import {Table} from 'react-bootstrap'
import Axios from 'axios'
import swal from 'sweetalert'

import $ from 'jquery'

import '../../css/user.css'

const urlUser = process.env.REACT_APP_BACKEND_URL+"/user/getUsers";

export default function Utilisateur() {

    const [refresh, setRefresh] = useState()
    const [allUser, setAlluser] = useState([])
    const [numPage, setNumPage] = useState(1);

    const handlerDelete = (event, index, key) => {
        event.preventDefault();

        $(`input[id=btn-delete-admin][name=${key}]`).val("Chargement")
        swal({
            title: "Confirmation",
            text: "Voulez-vous vraiment supprimer cet utilisateur ?",
            icon: "warning",
            buttons: {
                cancel: "Annuler",
                confirm: "Confirmer"
            },
            dangerMode: true,
        }).then((result) => {
            if(result === true){
                Axios.delete(process.env.REACT_APP_BACKEND_URL+"/admin/deleteAdmin/"+index).then((response) => {
                    if(response.data.success){
                        swal({
                            icon: "success",
                            title: "Supprimé!",
                            text: "L'utilisateur a été bien supprimé."
                        }).then(() => {
                            $(`input[id=btn-delete-admin][name=${key}]`).val("Supprimer")
                            setRefresh({})
                        })
                    }else{
                        swal({
                            icon: "error",
                            title: "Erreur",
                            text: "Une erreur s'est produit, veuillez réessayer ultérieurement."
                        }).then(() => {
                            $(`input[id=btn-delete-admin][name=${key}]`).val("Supprimer")
                            setRefresh({})
                        })
                    }
                })
            }else   $(`input[id=btn-delete-admin][name=${key}]`).val("Supprimer")

        })
        
    }

    async function fetchUser(){
        const urlgetUser = urlUser +"/"+ numPage;
        await Axios.get(urlgetUser).then((res)=>
            setAlluser(res.data.usersForPage)
        ).catch((err)=>console.log("Une erreur s'est produite : "+ err))
    }  

    useEffect(() => {
        let mounted = true;
        if(mounted){
            fetchUser()
        }
        return () => {
            mounted = false
        }
    }, [urlUser, refresh])

    return (
        
        <div className="container-utilisateur">
            <div className="container-table-utilisateur">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nom d'utilisateur</th>
                            <th>Nom et Prénom</th>
                            <th>e-mail</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(allUser)?(

                            allUser.map((user, key) => {
                                return(
                                    <tr key={key}>
                                        <td><strong>{key+1}</strong></td>
                                        <td>{user.username}</td>
                                        <td>{user.nom} {user.prenom}</td>
                                        <td>{user.email}</td>
                                        <td className="d-flex flex-row justify-content-center"><input id="btn-delete-admin" type="submit" value="Supprimer" name={key} onClick={(event) => {fetchUser(); handlerDelete(event, user._id, key)}} /></td>
                                    </tr>
                                )
                            })
                                    ):undefined
                        }
                    </tbody>

                </Table>
            </div>
        </div>
    )
}
